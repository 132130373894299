<template>
    <div class="video-box">
        <video width="320" height="240" controls playsinline='' webkit-playsinline='' x5-playsinline='' x-webkit-airplay='allow'>
           <source :src="propValue" type="video/mp4">
        </video>
    </div>

</template>

<script>
export default {
    props: {
        propValue: {
            type: String,
            require: true,
        },
    },
}
</script>

<style lang="scss" scoped>
</style>